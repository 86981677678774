<template>
  <section>
    <div class="pageBradcrumbs">
      <div class="containerLarge">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb small">
            <li class="breadcrumb-item">
              <b-button @click="toGlobal('/marine', 'marine')">Home</b-button>
            </li>
            <li class="breadcrumb-item">
              <b-button @click="toGlobal('/size-your-system', 'marine')">Size your system</b-button>
            </li>
          </ol>
        </nav>
      </div>
    </div>
    <div class="banner">
      <div
        class="bannerImage"
        :style="{
          'background-image':
            'url(' + require('../assets/img/banner/size-your-system.jpg') + ')',
        }"
      >
        <div class="containerLarge">
          <div class="pageTitle-banner text-center">
            <div class="pageTitle-content">
              <h1 class="mt-4">BWT • Ballast Water Treatment</h1>
              <div class="divider"></div>
              <p class="lead">
                Size Your System
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="containerLarge">
      <div class="row mt-5">
        <div class="col-md-10 offset-md-1 col-lg-6 offset-lg-3">
            <p class="lead text-center pb-lg-4">Answer the following questions to find the right product for your needs</p>
            <form class="steps-widget">
              <ul class="steps-list">
                <li class="active current" id="step1">
                  <div class="label"><b-button class="btn" @click="getFocus('step1',1)"><i></i></b-button> 
                    <h4>What is the flow rate of ballast system pumps?</h4>
                  </div>
                  <div class="desc">
                    <div class="wrap">
                      <div class="input-group mb-3">
                        <input v-model="flowrate" id="capacity" type="text" class="col-6 col-sm-3 col-lg-2 form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default">
                        <div class="input-group-append">
                            <span class="input-group-text" id="inputGroup-sizing-default">m<sup>3</sup>/h</span>
                        </div>
                      </div>
                      <div class="action">
                          <b-button id="showNext" class="btn btn-next btn-turquoise linkClick" @click="getFocus('step2',2)">Next</b-button>
                      </div>
                    </div>
                  </div>
                </li>
                <li id="step2" v-show="conditionStep2">
                  <div class="label"><b-button class="btn" @click="getFocus('step2',2)"><i></i></b-button> 
                    <h4>Do you need Explosion Proof version? </h4>
                  </div>
                  <div class="desc">
                    <div class="wrap">
                      <b-form-group >
                        <b-form-radio v-model="explosion" value="yes">Yes</b-form-radio>
                        <b-form-radio v-model="explosion" value="no">No</b-form-radio>
                      </b-form-group>
                      <div class="action">
                          <b-button class="btn btn-next btn-turquoise linkClick" @click="getFocus('step3',3)">Next</b-button>
                      </div> 
                    </div>
                  </div>
                </li>
                <li id="step3" v-show="conditionStep3">
                  <div class="label"><b-button class="btn" @click="getFocus('step3',3)"><i></i></b-button> 
                    <h4>System on skid or loose components?</h4>
                  </div>
                  <div class="desc">
                    <div class="wrap">
                      <b-form-group :disabled="conditionStep3Formgroup">
                        <b-form-radio v-model="componentType" value="skid">Skid</b-form-radio>
                        <b-form-radio v-model="componentType" value="loose">Loose Components</b-form-radio>
                      </b-form-group>
                      <div class="action">
                          <b-button class="btn btn-next btn-turquoise linkClick" @click="getFocus('step4',4)">Next</b-button>
                      </div> 
                    </div>
                  </div>
                </li>
                <li id="step4" v-show="conditionStep4">
                  <div class="label"><b-button class="btn" @click="getFocus('step4',4)"><i></i></b-button> 
                    <h4>Choose the cooling mode</h4>
                  </div>
                  <div class="desc">
                    <div class="wrap">
                      <b-form-group :disabled="conditionStep4Formgroup">
                        <b-form-radio v-model="coolingMode" value="cabinet">With Lamp Drive Cabinet air and water cooled, with start up of the sea water system and CIP unit with tank</b-form-radio>
                        <b-form-radio v-model="coolingMode" value="air">Air cooled, with start up of the fresh water system and with CIP unit with doser</b-form-radio>
                      </b-form-group>
                        <div class="action text-center mt-5">
                            <a v-if="!flag" class="btn btn-lg btn-turquoise linkClick" @click="getResult">Size your System</a>
                        </div>
                    </div>
                </div>
                </li>
                
              </ul>
              <div class="wrap text-center mt-5"> <!-- non spostare altrimenti non visibile -->
                <a v-if="flag" class="btn btn-lg btn-turquoise linkClick" @click="getResult">Size your System</a>
              </div>
            </form>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data: () => ({
    step: 1,
    flowrate: null,
    componentType: null,
    coolingMode: null,
    explosion: null,
    flag: false,
  }),

  computed: {
    getCurrentPath(){
      return this.$store.getters.getCurrentPath;
    },

    getCategoryName(){
      return this.$store.getters.getCategoryName; //this.$route.params.category_name;
    },

    getCategoryId(){
      return this.$store.getters.getCategoryId; //this.$route.params.category_id;
    },

    conditionStep2(){
      return Number(this.flowrate) >= 250 && Number(this.flowrate) <= 3000;
    },

    conditionStep3(){
      return this.flowrate !== null 
        && Number(this.flowrate) >= 32 && Number(this.flowrate <= 300) && this.explosion === 'no';
    },

    /* conditionStep3Formgroup(){
      return this.explosion === 'no' && Number(this.flowrate) > 300;
    },
    */
    conditionStep4(){
      return Number(this.flowrate) >= 171 && Number(this.flowrate) <= 1000 && this.componentType === 'loose';
    },

    /*  conditionStep4Formgroup(){
      return this.explosion === 'no' && Number(this.flowrate) > 1000 && this.componentType === 'loose';
    }, */
  },

  methods: {

    toGlobal(str, current){
      if (str !== this.getCurrentPath){
        this.$store.commit('setCurrentGlobal', current);
        this.$store.commit('setCurrentPath', str);
        this.$router.push(str);
      }      
    },

    getFocus(_stepname, stepnumber){
      this.flag = false;

      if (stepnumber === 2 && (Number(this.flowrate) < 250 || Number(this.flowrate) > 3000)){
        this.explosion = 'no';
        this.getFocus('step3', 3);        
      } else if (stepnumber === 3 && this.explosion === 'yes'){
        this.componentType = null;
        this.coolingMode = null;
        this.flag = true;
        this.getFocus('step4', 4);        
      } else if (stepnumber === 3 && this.explosion === 'no' && (Number(this.flowrate) < 32 || Number(this.flowrate) > 3000)){
        this.componentType = null;
        this.coolingMode = null;
        this.flag = true;
        this.getFocus('step4', 4);        
      } else if (stepnumber === 3 && this.explosion === 'no' && Number(this.flowrate) > 300){
        this.componentType = 'loose';
        this.getFocus('step4', 4);
        //this.insideStep(_stepname, stepnumber);      
      } else if (stepnumber === 4 && (Number(this.flowrate) < 171 || Number(this.flowrate) > 3000 
            || this.explosion === 'yes' || this.componentType !== 'loose')){
        this.coolingMode = null;
        this.flag = true;
        this.insideStep(_stepname, stepnumber);  
      } else if (stepnumber === 4 && this.explosion === 'no' && Number(this.flowrate) > 1000 
            && this.componentType === 'loose'){
        this.coolingMode = 'cabinet';
        this.flag = true;
        this.insideStep(_stepname, stepnumber);       
      } else {
        this.insideStep(_stepname, stepnumber);
      }
    },

    insideStep(_stepname, stepnumber){
      this.step = stepnumber;      
      this.resetSteps();
      const currentStep = document.getElementById(_stepname);
      currentStep.classList.add('active', 'current');
    },

    resetSteps(){
      const step1 = document.getElementById('step1');
      const step2 = document.getElementById('step2');
      const step3 = document.getElementById('step3');
      const step4 = document.getElementById('step4');

      step1.classList.remove('active', 'current');
      step2.classList.remove('active', 'current');
      step3.classList.remove('active', 'current');
      step4.classList.remove('active', 'current');
    },

    getResult(){
      this.$http.get('/api/v1/configuratore-bwt', {
        params: { 
          catId: this.getCategoryId,
          flowrate: this.flowrate,
          componentType: this.componentType,
          coolingMode: this.coolingMode,
          explosion: this.explosion,
        }
      })
        .then((resp) => {
          /* eslint-disable no-underscore-dangle */
          if (resp.data._id) {
            const usage = {};
            usage.productId = resp.data._id;
            usage.product = resp.data.name + ' ' + resp.data.size;
            usage.category = this.getCategoryName;
            this.$http.post('/api/datausage/save', usage)
              .then((ris) => {
                this.$store.commit('setCurrentGlobal', 'marine');
                this.$store.commit('setCurrentPath', '/product/' + resp.data._id);

                this.$router.push({
                  name: 'Product',
                  params: {
                    id: resp.data._id,
                    categoryId: this.getCategoryId
                  }
                });
                // this.$router.push('/product/' + resp.data._id);
              })
              .catch((err) => {
                console.error('Error on save datausage', err);
              });
            
          } else this.$router.push('/size-your-system/notfound');
        })
        .catch((err) => {
          console.error('Error on configurator', err);
        });
    }
  }
};
</script>
